/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import parse from 'html-react-parser'

// Elements
import Box from 'components/elements/Box'

const SpecialTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
`

const Specialties = ({ items }) => {
  return (
      items.map(({ id, title, uri }) => (
        <Link key={id} to={uri} className="col-lg-4 py-4 py-lg-0 px-5 mb-5">
          <Box hover className="py-5">
            <SpecialTitle className="mb-0 text-center py-1">
              {parse(title)}
            </SpecialTitle>
          </Box>
        </Link>
      ))
  )
}

export default Specialties
