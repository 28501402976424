/* eslint-disable arrow-body-style */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import R from 'img/R.svg';

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'
import Box from 'components/elements/Box'
import ImageBackground from 'components/elements/ImageBackground'
import Specialties from 'components/Specialties'
import Services from 'components/Services'
import KoelMonteurs from 'components/shared/before-footer/Koelmonteurs'
import ProjectsSection from 'components/shared/ProjectsSection'
import Plaatjie from '@ubo/plaatjie'


const Slogan = styled(Box)`
  position: relative;
  font-size: ${props => props.theme.font.size.xm};

  &::after {
    content: '';
    background-color: ${props => props.theme.color.secondary};
    width: 1100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: -1100%;
  }
`

const ServiceImage = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  min-height: 448px;
  position: absolute !important;
  z-index: 1;
  pointer-events: none;
`

const ServiceContainer = styled.div`
  min-height: 448px;
`

const ServiceRow = styled.div`
  position: relative;

  &::after {
    content: '';
    background-color: ${props => props.theme.color.alt};
    width: 1100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: -1100%;
  }
`


const StyledHero = styled.section`
  position: relative;

  height: 750px;

  @media screen and (max-width: 991px) {
    height: 400px;
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  min-height: 750px;
  position: absolute;

  @media screen and (max-width: 991px) {
    min-height: 400px;
  }
`

const StyledBox = styled(Box)`
  position: relative;
  
  & h1, & p {
    color: #fff;
  }

  & p {
    font-size: ${props => props.theme.font.size.xm};
  }

  @media screen and (max-width: 991px) {
    top: 40px;

    & h1 {
      font-size: ${props => props.theme.font.size.l};
    }
  }
`

const StyledR = styled.img`
  position:absolute;
  top: 100%;
  left: 0;
`;

const HomeTemplate = ({
  data: {
    page: {
      uri,
      seo,
      pageHome
    },
  },
}) => {
 
  return (
    <Layout>
      {seo && (
        <SEO
          seo={seo}
          socialImage={pageHome.homebanner.image}
        />
      )}

      <StyledHero>
        <Image image={pageHome.homebanner.image} alt="Banner" />
          <div className="content container h-100">
            <div className="row justify-content-center justify-content-lg-start h-100 align-items-center">
              <StyledBox className="col-10 col-lg-5">
                <h1 className="mb-3">{pageHome.homebanner.title}</h1>
                <ParseContent content={pageHome.homebanner.description} />
                <ButtonDefault to={pageHome.homebanner.buttonUrl} white>{pageHome.homebanner.buttonText}</ButtonDefault>
              </StyledBox>
            </div>
          </div>

        <StyledR src={R} alt="" />
      </StyledHero>

      <section>
        <div className="container py-5">
          <div className="row pt-5">
            <div className="col-lg-6">
              <h2 className="text-lg-right">{pageHome.homeintro.title}</h2>
            </div>
            <div className="col-lg-6" />
          </div>
          <div className="row">
            <div className="col-lg-6" />
            <div className="col-lg-6 pr-5">
              <ParseContent content={pageHome.homeintro.description} />
              <Slogan className="mt-5">{pageHome.homeintro.slogan}</Slogan>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pb-5">
          <div className="row py-5">
            <div className="col-12">
              <h2 className="text-center mb-3">{pageHome.speciality.title}</h2>
            </div>
          </div>
          <div className="row">
            <Specialties items={pageHome.speciality.item} />
          </div>
        </div>
      </section>
      <section className="py-lg-5">
        <div className="d-none d-lg-block col-lg-8 pl-0 position-relative">
          <ServiceImage image={pageHome.service.image} alt="" />
        </div>
        <ServiceContainer className="container h-100">
          <div className="row py-lg-5">
            <div className="col-lg-8" />
            <div className="col-lg-4">
              <h2 className="mb-4">{pageHome.service.title}</h2>
              <ParseContent content={pageHome.service.description} />
            </div>
          </div>
          <ServiceRow className="row py-4 color-background-alt">
            <div className="col-lg-8" />
            <div className="col-lg-4 d-flex flex-wrap">
              <Services items={pageHome.service.services} />
            </div>
          </ServiceRow>
        </ServiceContainer>
      </section>

      {/* <ProjectsSection /> */}
      <KoelMonteurs />
    </Layout>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      ...HomeFrag
    }
  }
`