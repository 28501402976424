/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Icon
import ArrowRight from 'img/arrow-right.svg'

// Helpers
import { getLanguage } from 'services/language'

const Service = styled(Link)`
  color: ${props => props.theme.color.text.main};
  font-size: ${props => props.theme.font.size.m};
  display: flex;
  align-items: center;

  & img {
    margin-right: 15px;
  }
`

const Services = ({ items }) => {

  return items.map(({ title, uri }) => (
    <Service to={uri} className="col-lg-6 mb-3">
      <img src={ArrowRight} alt="" />
      {title}
    </Service>
  ))
}

export default Services
